import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, message, Modal, Select } from 'antd';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import moment from 'moment';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { examTypes } from '../../appointments/exam-types';
import { AppointmentForm, ExamType } from '../../models/appointment';
import { Patient } from '../../models/patient';
import { RightAlignedDiv } from '../../shared/RightAlignedDiv';

const { Option } = Select;

export const AddAppointmentForPatient = ({ patient }: { patient: Patient }) => {
  const [visible, setVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedExamType, setSelectedExamType] = useState<ExamType>();
  const [authUser] = useAuthState(getAuth());

  const createAppointment = async ({ date }: AppointmentForm) => {
    setSubmitting(true);

    const startDate = moment(date);
    const endDate = moment(startDate).add(selectedExamType?.duration, 'm');

    try {
      if (patient) {
        await addDoc(collection(getFirestore(), 'appointments'), {
          patient,
          examType: selectedExamType,
          startDate: Timestamp.fromDate(startDate.toDate()),
          endDate: Timestamp.fromDate(endDate.toDate()),
          timeCreated: serverTimestamp(),
          updated: serverTimestamp(),
          createdBy: authUser?.uid,
        });
      }
    } catch (error) {
      console.error(error);
      message.error('Hiba történt');
      setSubmitting(false);
    }

    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <PlusOutlined />
      </Button>
      <Modal
        title="Időpont hozzáadasa"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          onFinish={createAppointment}
          layout="vertical"
          initialValues={{
            date: moment().set('h', 0).set('m', 0).set('s', 0),
            startTime: moment().set('h', 0).set('m', 0).set('s', 0),
            duration: 30,
          }}
        >
          <Form.Item
            label="Időpont"
            name={'date'}
            rules={[{ required: true, message: 'Kötelező mező' }]}
          >
            <DatePicker
              showTime
              showSecond={false}
              showNow={false}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item
            label="Vizsgálat típusa"
            name="examType"
            rules={[{ required: true, message: 'Kötelező mező' }]}
          >
            <Select
              showSearch
              onSelect={(selected: string) => {
                setSelectedExamType(
                  examTypes.find((examType) => examType.name === selected)
                );
              }}
            >
              {examTypes.map(({ name, duration, price }) => (
                <Option
                  value={name}
                  key={name}
                >{`${name} | ${duration} perc | ${price} Ft`}</Option>
              ))}
            </Select>
          </Form.Item>
          <RightAlignedDiv>
            <Button
              onClick={() => {
                setVisible(false);
              }}
              style={{ marginRight: '8px' }}
            >
              Bezár
            </Button>
            <Button type="primary" htmlType="submit" disabled={isSubmitting}>
              {isSubmitting ? <LoadingOutlined /> : 'Mentés'}
            </Button>
          </RightAlignedDiv>
        </Form>
      </Modal>
    </>
  );
};
