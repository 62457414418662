import {
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import algoliasearch from 'algoliasearch/lite';
import { Button, Input, message, Modal, Space, Table } from 'antd';
import {
  collection,
  doc,
  documentId,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Patient } from '../models/patient';
import { idConverter } from '../shared/converters';
import { RightAlignedDiv } from '../shared/RightAlignedDiv';
import debounce from 'lodash.debounce';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import moment from 'moment';

const searchClient = algoliasearch(
  'LSUM0IUQ72',
  '69f9c17a6d5587acb64826ea2eb8b31c'
);
const index = searchClient.initIndex('patients');

export const Patients = () => {
  const [patients, loading, error] = useCollectionData<Patient>(
    query(
      collection(getFirestore(), 'patients').withConverter(idConverter),
      orderBy('updated', 'desc'),
      limit(10)
    )
  );
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<Patient[]>([]);
  const [searchData, setSearchData] = useState<Patient[]>();
  useEffect(() => {
    if (searchResults.length > 0) {
      return onSnapshot(
        query(
          collection(getFirestore(), 'patients'),
          where(
            documentId(),
            'in',
            searchResults.map((hit) => hit.id)
          )
        ),
        (snapshot) => {
          setSearchData(
            searchResults.reduce((acc, hit) => {
              const document = snapshot.docs.find((doc) => doc.id === hit.id);

              if (document) {
                acc.push({ ...document.data(), id: document.id } as Patient);
              }

              return acc;
            }, [] as Patient[])
          );
        }
      );
    }
  }, [searchResults]);
  const getSearchResults = async (searchInput: string) => {
    if (searchInput) {
      const { hits } = await index.search<Patient>(searchInput, {
        hitsPerPage: 10,
        attributesToHighlight: [],
        restrictSearchableAttributes: ['name'],
      });

      if (hits.length === 0) {
        setShowSearchResults(true);
        setSearchResults([]);

        return;
      }

      setShowSearchResults(true);
      setSearchResults(hits.map((hit) => ({ ...hit, id: hit.objectID })));
    } else {
      setShowSearchResults(false);
      setSearchResults([]);
    }
  };
  const debounceFn = useMemo(() => debounce(getSearchResults, 500), []);
  const columns = [
    {
      title: (
        <Space>
          Név
          <Input
            placeholder="Kereső"
            onChange={(event) => debounceFn(event.target.value)}
            allowClear={true}
          />
        </Space>
      ),
      dataIndex: 'name',
      render: (name: string) => name,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => email,
    },
    {
      title: 'Szül. dátum',
      dataIndex: 'birthDate',
      render: (birthDate: Timestamp) =>
        birthDate
          ? moment(birthDate.toDate()).format('YYYY-MM-DD').toString()
          : '',
    },
    {
      title: <AddPatientButton />,
      dataIndex: ['operation'],
      width: '20%',
      render: (_: undefined, patient: Patient) => (
        <>
          <InfoButton patientId={patient.id} />
          <EditPatientButton patientId={patient.id} />
          <DeletePatientModal patient={patient} />
        </>
      ),
    },
  ];

  if (error) {
    console.error(error);
  }

  return (
    <Table
      dataSource={showSearchResults ? searchData : patients}
      pagination={false}
      columns={columns}
      rowKey="id"
      loading={loading}
    />
  );
};

const DeletePatientModal = ({ patient }: { patient: Patient }) => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const deletePatient = async () => {
    setSubmitting(true);

    try {
      const batch = writeBatch(getFirestore());
      const appointments = await getDocs(
        query(
          collection(getFirestore(), 'appointments'),
          where('patient.id', '==', patient.id)
        )
      );

      appointments.forEach((doc) => {
        batch.delete(doc.ref);
      });

      const docs = await getDocs(
        query(collection(getFirestore(), `patients/${patient.id}/documents`))
      );

      docs.forEach((doc) => {
        const data = doc.data();
        deleteObject(ref(getStorage(), data.fullPath));
      });

      batch.delete(doc(getFirestore(), 'patients', patient.id));
      setSubmitting(false);
      setVisible(false);
      await batch.commit();
    } catch (error) {
      console.error(error);
      message.error('Hiba történt');
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <DeleteOutlined />
      </Button>
      <Modal
        title={`Biztosan törölni szeretnéd ${patient.name}?`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <RightAlignedDiv>
          <Button
            onClick={() => setVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={() => deletePatient()}>
            {submitting ? <LoadingOutlined /> : 'Törlés'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};

const InfoButton = ({ patientId }: { patientId: string }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(`/patients/${patientId}?tabKey=documents`)}>
      <InfoOutlined />
    </Button>
  );
};

const AddPatientButton = () => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate('/patients/add-patient')}>
      <PlusOutlined />
    </Button>
  );
};

const EditPatientButton = ({ patientId }: { patientId: string }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(`/patients/${patientId}?tabKey=edit`)}>
      <EditOutlined />
    </Button>
  );
};
