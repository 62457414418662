import {
  LoginOutlined,
  MailOutlined,
  CalendarOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import { signOut, getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRole } from '../shared/hooks';

export const MenuBar = () => {
  const [authUser] = useAuthState(getAuth());
  const [role] = useRole();
  const location = useLocation();

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[location.pathname.split('/')[1]] ?? null}
      >
        {authUser ? (
          <>
            {role > 1 && (
              <Menu.Item key="public-calendar" icon={<CalendarOutlined />}>
                <Link to="/public-calendar">Naptár</Link>
              </Menu.Item>
            )}
            {role <= 1 && (
              <>
                <Menu.Item key="calendar" icon={<CalendarOutlined />}>
                  <Link to="/calendar">Naptár</Link>
                </Menu.Item>
                <Menu.Item key="appointments" icon={<CalendarOutlined />}>
                  <Link to="/appointments">Időpontok</Link>
                </Menu.Item>
                <Menu.Item key="patients" icon={<ContainerOutlined />}>
                  <Link to="/patients">Páciensek</Link>
                </Menu.Item>
              </>
            )}
            {role === 0 && (
              <Menu.Item key="users" icon={<UsergroupAddOutlined />}>
                <Link to="/users">Felhasználók</Link>
              </Menu.Item>
            )}
            <Menu.Item
              key="sign-out"
              icon={<LogoutOutlined />}
              onClick={() => {
                signOut(getAuth());
              }}
            >
              Kijelentkezés
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key="sign-in" icon={<LoginOutlined />}>
              <Link to="/sign-in">Bejelentkezés</Link>
            </Menu.Item>
            <Menu.Item key="forgot-password" icon={<MailOutlined />}>
              <Link to="/forgot-password">Elfelejtett jelszó</Link>
            </Menu.Item>
          </>
        )}
      </Menu>

      <Outlet />
    </>
  );
};
