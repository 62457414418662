import { Tabs } from 'antd';
import { FC } from 'react';
import { Patient } from '../../models/patient';
import { AddDocument } from './AddDocument';
import { DocumentDropZone } from './DocumentDropZone';

const { TabPane } = Tabs;

export const AddDocumentTabs: FC<{ patient: Patient }> = ({ patient }) => {
  return (
    <Tabs type="card">
      <TabPane tab="Meglévő fájl" key="2">
        <DocumentDropZone patient={patient} />
      </TabPane>
      <TabPane tab="Új fájl" key="1">
        <AddDocument patient={patient} />
      </TabPane>
    </Tabs>
  );
};
