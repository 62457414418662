import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';

type EmailAndPassword = {
  email: string;
  password: string;
};

export const SignIn = () => {
  const [isSubmitting, setSubmitting] = useState(false);

  const signIn = async ({ email, password }: EmailAndPassword) => {
    setSubmitting(true);

    await signInWithEmailAndPassword(getAuth(), email, password).catch(
      (error) => {
        if (error.code === 'auth/user-not-found') {
          message.error('email cím nem található');
        } else if (error.code === 'auth/wrong-password') {
          message.error('Hibás jelszó');
        } else {
          console.error(error);
        }

        setSubmitting(false);
      }
    );
  };

  return (
    <Form onFinish={signIn}>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true }, { type: 'email' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Jelszó"
        name="password"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          {isSubmitting ? <LoadingOutlined /> : 'Bejelentkezés'}
        </Button>
      </Form.Item>
    </Form>
  );
};
