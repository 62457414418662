import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select } from 'antd';
import { FC, useState } from 'react';
import { getUserRole, UserForm } from '../models/user';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { User } from 'firebase/auth';
import { RightAlignedDiv } from '../shared/RightAlignedDiv';
import { getApp } from 'firebase/app';

type Props = { setIsModalVisible: (visibility: boolean) => void };
const { Option } = Select;

export const AddUserForm: FC<Props> = ({ setIsModalVisible }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const createUser = async (userForm: UserForm) => {
    setSubmitting(true);

    try {
      await httpsCallable<UserForm, User>(
        getFunctions(getApp(), 'europe-west1'),
        'addUser'
      )(userForm);
    } catch (err: any) {
      if (err.response.data.code === 'auth/email-already-exists') {
        message.error('Email cím mar használatban');
      } else {
        message.error('Hiba történt');
        console.error(err);
      }
    }

    setSubmitting(false);
    setIsModalVisible(false);
  };

  return (
    <Form onFinish={createUser} initialValues={{ role: 1 }}>
      <Form.Item
        label="Név"
        name="displayName"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Kötelező mező' },
          { type: 'email', message: 'helytelen email' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Jelszó"
        name="password"
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item label="Jogosultság" name="role">
        <Select>
          <Option value={0}>{getUserRole(0)}</Option>
          <Option value={1}>{getUserRole(1)}</Option>
          <Option value={2}>{getUserRole(2)}</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <RightAlignedDiv>
          <Button
            onClick={() => setIsModalVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Bezár
          </Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting ? <LoadingOutlined /> : 'Hozzáadás'}
          </Button>
        </RightAlignedDiv>
      </Form.Item>
    </Form>
  );
};
