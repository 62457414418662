import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Appointment } from '../models/appointment';
import AddPublicAppointmentForm from './AddPublicAppointmentForm';

const AddPublicAppointmentModal = ({
  appointment,
}: {
  appointment?: Appointment;
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <PlusOutlined />
      </Button>
      <Modal
        title="Időpont hozzáadasa"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <AddPublicAppointmentForm
          appointment={appointment}
          setVisible={setVisible}
          formInstance={form}
        />
      </Modal>
    </>
  );
};

export default AddPublicAppointmentModal;
