// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyClV3pEIbyQwljwF4NG6hb_nGpeY_Pls-0',
  authDomain: 'react-clinic-manager.firebaseapp.com',
  projectId: 'react-clinic-manager',
  storageBucket: 'react-clinic-manager.appspot.com',
  messagingSenderId: '110828697842',
  appId: '1:110828697842:web:c42007ac37c7aee331b86b',
  measurementId: 'G-03BEV3WE6M',
};
