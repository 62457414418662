export const idConverter = {
  toFirestore: (data: any) => data,
  fromFirestore: (snap: any) => ({
    id: snap.id,
    ...snap.data(),
  }),
};

export const removeUndefined = ({ ...data }: any) => {
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key]
  );

  return data;
};
