import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { FC, useState } from 'react';
import { Patient, PatientDocument } from '../../models/patient';
import { RightAlignedDiv } from '../../shared/RightAlignedDiv';

export const DeleteDocumentModal: FC<{
  patient: Patient;
  document: PatientDocument;
}> = ({ patient, document }) => {
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  const deleteDocument = async () => {
    setSubmitting(true);

    try {
      await deleteObject(ref(getStorage(), document.fullPath));

      await deleteDoc(
        doc(getFirestore(), `patients/${patient.id}/documents/${document.id}`)
      );
    } catch (err) {
      console.error(err);
      message.error('Hiba a törlés közben');

      setSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <DeleteOutlined />
      </Button>
      <Modal
        title={`Biztosan törölni szeretnéd ${document.fileName}?`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <RightAlignedDiv>
          <Button
            onClick={() => setVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={() => deleteDocument()}>
            {submitting ? <LoadingOutlined /> : 'Törlés'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};
