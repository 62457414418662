import {
  addDoc,
  collection,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import moment from 'moment';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Patient, PatientDocument } from '../../models/patient';

export const DocumentDropZone = ({ patient }: { patient: Patient }) => {
  const onDrop = useCallback(
    async ([file]: File[]) => {
      const date = moment();
      const fileName = `${file.name.split('.pdf')[0]}-${patient.name
        .split(' ')
        .join('-')}-${date.format('YYYYMMDDHHmmss')}.pdf`;
      const fullPath = `${patient.id}/${fileName}`;
      const document = await uploadBytes(ref(getStorage(), fullPath), file);
      const downloadURL = await getDownloadURL(document.ref);

      await addDoc(
        collection(getFirestore(), `patients/${patient.id}/documents`),
        {
          fileName,
          downloadURL,
          fullPath,
          timeCreated: Timestamp.fromDate(new Date()),
          updated: Timestamp.fromDate(new Date()),
          htmlContent: '',
          isDragAndDropped: true,
          alreadySent: false,
        } as PatientDocument
      );
    },
    [patient]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'application/pdf',
  });

  return (
    <DragAndDrop {...getRootProps()}>
      <input title="drag and drop" {...getInputProps()} />
      {isDragActive ? (
        <DragAndDropText>Dobd ide a fájlt...</DragAndDropText>
      ) : (
        <DragAndDropText>Húzd ide a fájl a feltöltéshez</DragAndDropText>
      )}
    </DragAndDrop>
  );
};

const DragAndDrop = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
`;
const DragAndDropText = styled.p`
  margin: 0;
`;
