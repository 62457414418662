import { Table } from 'antd';
import { Patient, PatientDocument } from '../../models/patient';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { idConverter } from '../../shared/converters';
import { collection, getFirestore, query, Timestamp } from 'firebase/firestore';
import { AddDocumentTabs } from './AddDocumentTabs';
import moment from 'moment';
import { DeleteDocumentModal } from './DeleteDocumentModal';
import { SendDocumentButton } from './SendDocumentButton';

export const Documents = ({ patient }: { patient: Patient }) => {
  const [documents] = useCollectionData<PatientDocument>(
    query(
      collection(getFirestore(), `patients/${patient.id}/documents`)
    ).withConverter(idConverter)
  );

  const columns = [
    {
      title: 'Fájlnév',
      dataIndex: 'fileName',
      render: (fileName: string, document: PatientDocument) => (
        <a href={document.downloadURL} target="_blank" rel="noreferrer">
          {fileName}
        </a>
      ),
    },
    {
      title: 'Létrehozva',
      dataIndex: 'timeCreated',
      render: (timeCreated: Timestamp) =>
        moment(timeCreated?.toDate()).format('YYYY. MM. DD. HH:mm').toString(),
    },
    {
      title: 'Műveletek',
      dataIndex: ['operation'],
      render: (_: undefined, document: PatientDocument) => (
        <>
          <SendDocumentButton patient={patient} document={document} />
          <DeleteDocumentModal patient={patient} document={document} />
        </>
      ),
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={documents} rowKey="id" />
      <AddDocumentTabs patient={patient} />
    </>
  );
};
