import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { Appointment } from '../models/appointment';
import { AddAppointmentForm } from './AddAppointmentForm';

export const AddAppointmentModal = ({
  appointment,
}: {
  appointment?: Appointment;
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        {appointment ? <EditOutlined /> : <PlusOutlined />}
      </Button>
      <Modal
        title="Időpont módosítása"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <AddAppointmentForm
          setVisible={setVisible}
          appointment={appointment}
          formInstance={form}
        />
      </Modal>
    </>
  );
};
