import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

export const useRole = () => {
  const [user, userLoading] = useAuthState(getAuth());
  const [role, setRole] = useState(Infinity);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkIfAdmin = async () => {
      try {
        const { claims }: any = await user?.getIdTokenResult();

        setRole(claims.role);
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    };

    if (!userLoading) {
      user ? checkIfAdmin() : setLoading(false);
    }
  }, [user, userLoading]);

  return [role, loading];
};
