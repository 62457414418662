import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
} from 'antd';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';
import moment from 'moment';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Appointment } from '../models/appointment';
import { Patient, PatientForm } from '../models/patient';
import { idConverter, removeUndefined } from '../shared/converters';

const { Option } = Select;

export const EditPatientForm: FC<{
  patient: Patient;
}> = ({ patient }) => {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [billing, setBilling] = useState(patient.isBillingSame);

  const editPatient = async (patientForm: PatientForm) => {
    setSubmitting(true);

    const updatedPatient: Partial<Patient> = removeUndefined({
      ...patientForm,
      birthDate:
        patientForm.birthDate &&
        Timestamp.fromDate(patientForm.birthDate.toDate()),
      updated: serverTimestamp() as Timestamp,
    });

    try {
      const batch = writeBatch(getFirestore());

      batch.set(
        doc(getFirestore(), 'patients', patient.id).withConverter(idConverter),
        updatedPatient,
        {
          merge: true,
        }
      );

      const querySnapshot = await getDocs<Appointment>(
        query(
          collection(getFirestore(), 'appointments'),
          where('patient.id', '==', patient.id)
        ).withConverter(idConverter)
      );

      querySnapshot.forEach((snap) => {
        batch.set(snap.ref, { patient: updatedPatient }, { merge: true });
      });

      await batch.commit();

      message.success('Sikeres mentés');
      navigate('/patients');
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <Form
      onFinish={editPatient}
      layout="vertical"
      initialValues={{
        ...patient,
        birthDate: patient.birthDate
          ? moment(patient.birthDate.toDate())
          : null,
      }}
    >
      <Row>
        <Col span={12}>
          <Card title="Adatok">
            <Form.Item
              label="Név"
              name="name"
              rules={[{ required: true, message: 'Kötelező mező' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Kötelező mező' },
                { type: 'email', message: 'Helytelen email' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Telefonszám" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Anyja neve" name="mothersName">
              <Input />
            </Form.Item>
            <Space>
              <Form.Item label="Születési hely" name="birthPlace">
                <Input />
              </Form.Item>
              <Form.Item label="Születési dátum (éééé-hh-nn)" name="birthDate">
                <DatePicker />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item label="Azonosító okmány típusa" name="documentType">
                <Select>
                  <Option value="taj">TAJ</Option>
                  <Option value="szig">Személyi igazolvány</Option>
                  <Option value="passport">Útlevél</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Azonosító okmány száma" name="documentNumber">
                <Input />
              </Form.Item>
            </Space>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Lakcím">
            <Form.Item label="Irányítószám" name="zipCode">
              <Input />
            </Form.Item>
            <Form.Item label="Ország" name="country">
              <Input />
            </Form.Item>
            <Form.Item label="Város" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="Utca, házszam" name="street">
              <Input />
            </Form.Item>
          </Card>

          <Card title="Számlázás">
            <Form.Item label="Adószám" name="taxNumber">
              <Input />
            </Form.Item>
            <Form.Item
              label="Lakcím és számlázási cím azonos"
              name="isBillingSame"
              valuePropName="checked"
            >
              <Checkbox
                onChange={(event) => setBilling(event.target.checked)}
              />
            </Form.Item>
            {!billing && (
              <>
                <Form.Item label="Irányítószám" name="billingZipCode">
                  <Input />
                </Form.Item>
                <Form.Item label="Ország" name="billingCountry">
                  <Input />
                </Form.Item>
                <Form.Item label="Város" name="billingCity">
                  <Input />
                </Form.Item>
                <Form.Item label="Utca, házszam" name="billingStreet">
                  <Input />
                </Form.Item>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isSubmitting} block>
          {isSubmitting ? <LoadingOutlined /> : 'Mentés'}
        </Button>
      </Form.Item>
    </Form>
  );
};
