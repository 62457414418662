import { ExamType } from '../models/appointment';

export const examTypes: ExamType[] = [
  {
    name: 'Agykoponya kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Agykoponya natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Agykoponya kontrasztos angiográfiás MR',
    duration: 45,
    price: 89000,
  },
  {
    name: 'Agykoponya natív angiográfiás MR',
    duration: 30,
    price: 47900,
  },
  {
    name: 'Alkar kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Alkar natív MR',
    duration: 40,
    price: 38900,
  },
  {
    name: 'Arckoponya kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Arckoponya natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Belső fül natív és kontrasztos MR',
    duration: 60,
    price: 81900,
  },
  {
    name: 'Belső fül natív MR',
    duration: 45,
    price: 38900,
  },
  {
    name: 'Boka kontrasztos MR',
    duration: 45,

    price: 81900,
  },
  {
    name: 'Boka Artrographia (kontrasztos) MR ',
    duration: 40,
    price: 84900,
  },
  {
    name: 'Boka natív MR',
    duration: 30,
    price: 38900 ,
  },
  {
    name: 'Comb kontrasztos MR',
    duration: 60,
    price: 81900 ,
  },
  {
    name: 'Comb natív MR',
    duration: 45,
    price: 38900,
  },
  {
    name: 'Csípő kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Csípő Artrographia (kontrasztos) MR',
    duration: 40,
    price: 84900,
  },
  {
    name: 'Csípő natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Csukló kontrasztos MR ',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Csukló Artrographia (kontrasztos) MR',
    duration: 45,
    price: 84900,
  },
  {
    name: 'Csukló natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Emlő natív MR vizsgálata szilikon mérésekkel',
    duration: 45,
    price: 45900,
  },
  {
    name: 'Emlő kontrasztos MR',
    duration: 45,
    price: 84900,
  },
  {
    name: 'Felkar kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Felkar natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Gerinc - ágyéki kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Gerinc - ágyéki natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Gerinc - háti kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Gerinc - háti natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Gerinc - nyaki kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Gerinc - nyaki natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Has kontrasztos MR',
    duration: 60,
    price: 81900,
  },
  {
    name: 'Has natív MR',
    duration: 40,
    price: 38900,
  },
  {
    name: 'Has és kismedence kontrasztos MR',
    duration: 90,
    price: 104900,
  },
  {
    name: 'Keresztcsont (Sacrum) kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Keresztcsont (Sacrum) natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Kéz - ujjak ízületei kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Kéz - ujjak ízületei natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Kismedence kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Kismedence natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Kolangiográfia (MRCP) és has kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Kolangiográfia (MRCP) és has natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Könyök kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Könyök natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Könyök Artrographia (kontrasztos) MR',
    duration: 30,
    price: 84900,
  },
  {
    name: 'Lábfej kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Lábfej natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Lábszár kontrasztos MR',
    duration: 50,
    price: 81900,
  },
  {
    name: 'Lábszár natív MR',
    duration: 40,
    price: 38900,
  },
  {
    name: 'Axilla kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Mellkas kontrasztos MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Nyaki lágyrész kontrasztos MR',
    duration: 45,
    price: 81900,
  },
  {
    name: 'Nyaki lágyrész natív MR',
    duration: 40,
    price: 38900,
  },
  {
    name: 'Orbita lágyrész kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Orbita lágyrész natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Piriformis izom natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Sella (agyalapi mirigy) kontrasztos MR',
    duration: 25,
    price: 81900,
  },
  {
    name: 'Prosztata kontrasztos MR',
    duration: 30,
    price: 81900,
  },
  {
    name: 'Szív kontrasztos MR',
    duration: 50,
    price: 129900,
  },
  {
    name: 'Szív natív MR',
    duration: 40,
    price: 79900,
  },
  {
    name: 'Térd kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Térd natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Térd Artrographia (kontrasztos) MR',
    duration: 30,
    price: 84900,
  },
  {
    name: 'Váll kontrasztos MR',
    duration: 40,
    price: 81900,
  },
  {
    name: 'Váll natív MR',
    duration: 30,
    price: 38900,
  },
  {
    name: 'Váll Artrographia (kontrasztos) MR',
    duration: 30,
    price: 84900,
  },
  {
    name: 'Vékonybél (enterográfia) kontrasztos MR',
    duration: 50,
    price: 90000,
  },
  {
    name: 'Sürgősségi baleseti betegvizsgálat- sport baleset',
    duration: 30,
    price: 44900,
  },
  {
    name: 'Alkar röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Arckoponya röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Boka röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Boka egyirányú röntgen',
    duration: 15,
    price: 8000,
  },
  {
    name: 'Comb röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Csípő röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Csukló röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Felkar röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Gerinc - ágyéki röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Gerinc - háti röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Gerinc - nyaki röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Has röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Keresztcsont röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Kézfej röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Könyök röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Lábfej röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Lábszár röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Térd röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Orbita röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Mellkas röntgen',
    duration: 15,
    price: 12000,
  },
  {
    name: 'Mellkas egyirányú röntgen',
    duration: 15,
    price: 8000,
  },
  {
    name: 'Váll röntgen',
    duration: 15,
    price: 12000,
  },
];
