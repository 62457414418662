import './App.scss';
import { getApp, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { firebaseConfig } from './firebaseConfig';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import locale from 'antd/lib/locale/hu_HU';
import moment from 'moment';
import 'moment/locale/hu';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ConfigProvider } from 'antd';
import { SignIn } from './auth/SignIn';
import { PrivateRoute } from './routing/PrivateRoute';
import { Users } from './users/Users';
import { ResetPassword } from './auth/ResetPassword';
import { Appointments } from './appointments/Appointments';
import { Patients } from './patients/Patients';
import { Calendar } from './calendar/Calendar';
import { useRole } from './shared/hooks';
import { PatientDetails } from './patients/PatientDetails';
import { LoadingSpinner } from './shared/LoadingSpinner';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MenuBar } from './menu/MenuBar';
import { AddPatientForm } from './patients/AddPatientForm';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

moment.locale('hu');

initializeApp(firebaseConfig);
if (false && process.env.NODE_ENV === 'development') {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  connectFunctionsEmulator(
    getFunctions(getApp(), 'europe-west1'),
    'localhost',
    5001
  );
}

export const App = () => {
  const [authUser, loading] = useAuthState(getAuth());
  const [role, roleLoading] = useRole();

  if (loading || roleLoading) {
    return <LoadingSpinner />;
  }

  return (
    <ConfigProvider locale={locale}>
      <BrowserRouter>
        <Routes>
          <Route element={<MenuBar />}>
            <Route
              path="/"
              element={
                <Navigate
                  to={
                    authUser
                      ? role <= 1
                        ? '/calendar'
                        : '/public-calendar'
                      : 'sign-in'
                  }
                />
              }
            />
            <Route
              path="sign-in"
              element={
                <PrivateRoute redirectIf={!!authUser} to="/">
                  <SignIn />
                </PrivateRoute>
              }
            />
            <Route
              path="forgot-password"
              element={
                <PrivateRoute redirectIf={!!authUser} to="/">
                  <ResetPassword />
                </PrivateRoute>
              }
            />
            <Route
              path="public-calendar"
              element={
                <PrivateRoute redirectIf={!authUser || role < 2} to="/">
                  <Calendar publicMode={true} />
                </PrivateRoute>
              }
            />
            <Route
              path="calendar"
              element={
                <PrivateRoute redirectIf={!authUser || role > 1} to="/">
                  <Calendar />
                </PrivateRoute>
              }
            />
            <Route
              path="appointments"
              element={
                <PrivateRoute redirectIf={!authUser || role > 1} to="/">
                  <Appointments />
                </PrivateRoute>
              }
            />
            <Route path="patients">
              <Route
                index
                element={
                  <PrivateRoute redirectIf={!authUser || role > 1} to="/">
                    <Patients />
                  </PrivateRoute>
                }
              />
              <Route
                path=":patientId"
                element={
                  <PrivateRoute redirectIf={!authUser || role > 1} to="/">
                    <PatientDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="add-patient"
                element={
                  <PrivateRoute redirectIf={!authUser || role > 1} to="/">
                    <AddPatientForm />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="users"
              element={
                <PrivateRoute redirectIf={!authUser || role !== 0} to="/">
                  <Users />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
};
