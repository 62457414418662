import { Table, TablePaginationConfig } from 'antd';
import {
  collection,
  getFirestore,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { AddAppointmentModal } from '../../appointments/AddAppointmentModal';
import { DeleteAppointmentModal } from '../../appointments/DeleteAppointmentModal';
import { Appointment, ExamType } from '../../models/appointment';
import { Patient } from '../../models/patient';
import { idConverter } from '../../shared/converters';
import { AddAppointmentForPatient } from './AddAppointmentForPatient';

export const PatientAppointments = ({ patient }: { patient: Patient }) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [appointments] = useCollectionData<Appointment>(
    query(
      collection(getFirestore(), 'appointments'),
      where('patient.id', '==', patient.id),
      orderBy('startDate', 'desc')
    ).withConverter(idConverter)
  );

  const columns = [
    {
      title: 'Kezdes',
      dataIndex: 'startDate',
      render: (startDate: Timestamp) =>
        moment(startDate.toDate()).format('YYYY. MM. DD. HH:mm').toString(),
    },
    {
      title: 'Vizsgálat',
      dataIndex: 'examType',
      render: ({ name, duration }: ExamType) => `${name} | ${duration} perc`,
    },
    {
      title: 'Páciens',
      dataIndex: 'patient',
      render: (patient: Patient) => patient.name,
    },
    {
      title: <AddAppointmentForPatient patient={patient} />,
      dataIndex: ['operation'],
      render: (_: undefined, appointment: Appointment) => (
        <>
          <AddAppointmentModal appointment={appointment} />
          <DeleteAppointmentModal appointment={appointment} />
        </>
      ),
    },
  ];

  return (
    <Table
      dataSource={appointments}
      columns={columns}
      pagination={pagination}
      onChange={(x) => setPagination(x)}
      rowKey="id"
    />
  );
};
