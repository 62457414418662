import { Tabs } from 'antd';
import { doc, getFirestore } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams, useSearchParams } from 'react-router-dom';
import { Patient } from '../models/patient';
import { idConverter } from '../shared/converters';
import { LoadingSpinner } from '../shared/LoadingSpinner';
import { Documents } from './details/Documents';
import { PatientAppointments } from './details/PatientAppointments';
import { EditPatientForm } from './EditPatientForm';

const { TabPane } = Tabs;

export const PatientDetails = () => {
  const { patientId } = useParams();
  const [searchParams] = useSearchParams();
  const [patient, loading] = useDocumentData<Patient>(
    doc(getFirestore(), 'patients', patientId ?? '').withConverter(idConverter)
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!patient) {
    return <div>Nincs ilyen páciens</div>;
  }

  return (
    <Tabs
      defaultActiveKey={searchParams.get('tabKey') ?? 'documents'}
      type="card"
    >
      <TabPane tab="Dokumentumok" key="documents">
        <Documents patient={patient} />
      </TabPane>
      <TabPane tab="Időpontok" key="appointments">
        <PatientAppointments patient={patient} />
      </TabPane>
      <TabPane tab="Adatok" key="edit">
        <EditPatientForm patient={patient} />
      </TabPane>
    </Tabs>
  );
};
