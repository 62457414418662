import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import {
  Button,
  Input,
  Modal,
  Space,
  Table,
  TablePaginationConfig,
} from 'antd';
import { getApp } from 'firebase/app';
import { collection, getFirestore, orderBy, query } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FC, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { getUserRole, User } from '../models/user';
import { idConverter } from '../shared/converters';
import { RightAlignedDiv } from '../shared/RightAlignedDiv';
import { AddUserForm } from './AddUserForm';
import { EditUserForm } from './EditUserForm';

const AddUserModal = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <PlusOutlined />
      </Button>
      <Modal
        title="Felhasználó hozzáadasa"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <AddUserForm setIsModalVisible={setVisible} />
      </Modal>
    </>
  );
};

const EditUserModal: FC<{ user: User }> = ({ user }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <EditOutlined />
      </Button>
      <Modal
        title="Felhasználó módosítása"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <EditUserForm user={user} setIsModalVisible={setVisible} />
      </Modal>
    </>
  );
};

const DeleteUserModal: FC<{ user: User }> = ({ user }) => {
  const [state, setState] = useState({ visible: false, isSubmitting: false });

  const deleteUser = async () => {
    setState({ ...state, isSubmitting: true });

    // TODO delete anything related to user on deletion e.g auth, relevant Patients, Records, etc.
    try {
      await httpsCallable<any, User>(
        getFunctions(getApp(), 'europe-west1'),
        'deleteUser'
      )({ uid: user.id });
    } catch (error) {
      console.error(error);
    }

    setState({ ...state, visible: false, isSubmitting: false });
  };

  return (
    <>
      <Button onClick={() => setState({ ...state, visible: true })}>
        <DeleteOutlined />
      </Button>
      <Modal
        title={`Biztosan törölni szeretnéd ${user.displayName} felhasználót?`}
        visible={state.visible}
        onCancel={() => setState({ ...state, visible: false })}
        footer={null}
      >
        <RightAlignedDiv>
          <Button
            onClick={() => setState({ ...state, visible: false })}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={() => deleteUser()}>
            {state.isSubmitting ? <LoadingOutlined /> : 'Törlés'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};

const EditPassword = ({ user }: { user: User }) => {
  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState('');

  const editPassword = async () => {
    setSubmitting(true);

    try {
      await httpsCallable<any, User>(
        getFunctions(getApp(), 'europe-west1'),
        'editPassword'
      )({ uid: user.id, password });
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
    setVisible(false);
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <RocketOutlined />
      </Button>
      <Modal
        title={`Jelszo modositasa ${user.displayName} felhasznalohoz`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Space>
          <Input.Password
            value={password}
            placeholder="Jelszo"
            onChange={(event) => setPassword(event.target.value)}
          />
        </Space>
        <RightAlignedDiv>
          <Button
            onClick={() => setVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={() => editPassword()}>
            {submitting ? <LoadingOutlined /> : 'Mentes'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    render: (email: string) => email,
  },
  {
    title: 'Név',
    dataIndex: 'displayName',
    render: (displayName: string) => displayName,
  },
  {
    title: 'Jogosultság',
    dataIndex: 'role',
    render: (role: number) => getUserRole(role),
  },
  {
    title: <AddUserModal />,
    dataIndex: ['operation'],
    render: (_: undefined, user: User) => (
      <>
        <EditPassword user={user} />
        <EditUserModal user={user} />
        <DeleteUserModal user={user} />
      </>
    ),
  },
];

export const Users = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [users] = useCollectionData<User>(
    query(
      collection(getFirestore(), 'users'),
      orderBy('role', 'desc'),
      orderBy('updated', 'desc')
    ).withConverter(idConverter)
  );

  return (
    <Table
      dataSource={users}
      columns={columns}
      pagination={pagination}
      onChange={(x) => setPagination(x)}
      rowKey="id"
    />
  );
};
