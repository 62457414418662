import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';

export const ResetPassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);

  const resetPassword = async ({ email }: { email: string }) => {
    setSubmitting(true);

    await sendPasswordResetEmail(getAuth(), email).catch((error) => {
      if (error.code === 'auth/user-not-found') {
        message.error('Email cím nem talalhato');
      } else {
        console.error(error);
      }
    });

    setSubmitting(false);
    message.success('Jelszó visszaallito linket emailben elkuldtuk');
  };

  return (
    <Form onFinish={resetPassword}>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Kötelező mező' },
          { type: 'email', message: 'Rossz email formátum' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          {isSubmitting ? <LoadingOutlined /> : 'Új jelszó kérése'}
        </Button>
      </Form.Item>
    </Form>
  );
};
