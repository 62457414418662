import { LoadingOutlined } from '@ant-design/icons';
import { Input, Button, Form, message } from 'antd';
import { getApp } from 'firebase/app';
import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp,
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import moment from 'moment';
import { Editor } from 'primereact/editor';
import { FC, useState } from 'react';
import { Patient } from '../../models/patient';

export const AddDocument: FC<{ patient: Patient }> = ({ patient }) => {
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  const save = async ({ fileName }: { fileName: string }) => {
    const prefix = fileName ? fileName + '-' : '';

    setIsSaving(true);

    try {
      const fileName = `${prefix}${patient.name
        .split(' ')
        .join('-')}-${moment().format('YYYYMMDDHHmmss')}.pdf`;
      const { data } = await httpsCallable<
        { patientId: string; fileName: string; htmlContent: string },
        { fullPath: string }
      >(
        getFunctions(getApp(), 'europe-west1'),
        'createPdfRecordFromHTML'
      )({
        patientId: patient.id,
        fileName,
        htmlContent,
      });

      await addDoc(
        collection(getFirestore(), `patients/${patient.id}/documents`),
        {
          fileName,
          fullPath: data.fullPath,
          timeCreated: serverTimestamp(),
          updated: serverTimestamp(),
          isDragAndDropped: false,
          alreadySent: false,
          htmlContent,
          downloadURL: await getDownloadURL(ref(getStorage(), data.fullPath)),
        }
      );
    } catch (err) {
      console.error(err);
      message.error('Hiba történt a felöltéskor');
    }

    setIsSaving(false);
  };

  return (
    <Form onFinish={save}>
      <Form.Item label="Fájlnév" name="fileName">
        <Input
          addonAfter={`-${patient.name.split(' ').join('-')}-${moment().format(
            'YYYYMMDDHHmmss'
          )}.pdf`}
        />
      </Form.Item>
      <Editor
        style={{ height: '320px' }}
        value={htmlContent}
        onTextChange={(e) => setHtmlContent(e.htmlValue as string)}
      />
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={isSaving}>
          {isSaving ? <LoadingOutlined /> : 'Mentés'}
        </Button>
      </Form.Item>
    </Form>
  );
};
