import { Timestamp } from 'firebase/firestore';

export interface User {
  email: string;
  id: string;
  displayName: string;
  role: number;
  timeCreated: Timestamp;
  updated: Timestamp;
}

export type UserForm = {
  email: string;
  displayName: string;
  role: number;
};

const userRoles = ['Adminisztrator', 'Moderator', 'Felhasznalo'];

export const getUserRole = (id: number) => userRoles[id];
