import { LoadingOutlined } from '@ant-design/icons';
import algoliasearch from 'algoliasearch/lite';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Popconfirm,
  Select,
  Switch,
} from 'antd';
import { getAuth } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Appointment, AppointmentForm } from '../models/appointment';
import { Patient } from '../models/patient';
import { idConverter, removeUndefined } from '../shared/converters';
import { FlexDiv } from '../shared/RightAlignedDiv';
import { examTypes } from './exam-types';

const moment = extendMoment(Moment);
const searchClient = algoliasearch(
  'LSUM0IUQ72',
  '69f9c17a6d5587acb64826ea2eb8b31c'
);
const index = searchClient.initIndex('patients');
const { Option } = Select;
const getPatientEmailAndName = (patient: Patient) =>
  patient ? `${patient.name}, ${patient.email}` : '';

export const AddAppointmentForm = ({
  appointment,
  initialStartDate,
  formInstance,
  setVisible,
}: {
  appointment?: Appointment;
  initialStartDate?: moment.Moment;
  formInstance?: FormInstance;
  setVisible: (visibility: boolean) => void;
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [results, setResults] = useState<Patient[]>([]);
  const [selectedPatient, setSelectedPatient] = useState(appointment?.patient);
  const [selectedExamType, setSelectedExamType] = useState(
    appointment?.examType
  );
  const [form] = Form.useForm(formInstance);
  const [authUser] = useAuthState(getAuth());
  const [addNewPatient, setAddNewPatient] = useState(false);

  const onSearch = async (searchText: string) => {
    if (searchText) {
      const { hits } = await index.search<Patient>(searchText, {
        hitsPerPage: 10,
        attributesToHighlight: [],
      });

      setResults(
        hits.map(({ objectID, ...hit }) => ({
          ...hit,
          id: objectID,
        }))
      );
    } else {
      setResults([]);
    }
  };

  const createPatient = async (
    email = '',
    patientName = '',
    phone = ''
  ): Promise<Patient> => {
    const existingPatients = await getDocs<Patient>(
      query(
        collection(getFirestore(), 'patients'),
        where('email', '==', email),
        where('name', '==', patientName)
      ).withConverter(idConverter)
    );

    if (existingPatients.empty) {
      const newPatient = removeUndefined({
        name: patientName,
        email,
        phone,
        timeCreated: serverTimestamp() as Timestamp,
        updated: serverTimestamp() as Timestamp,
        createdBy: authUser?.uid,
      });

      const { id } = await addDoc(
        collection(getFirestore(), 'patients'),
        newPatient
      );

      return { ...newPatient, id };
    } else {
      return existingPatients.docs[0].data();
    }
  };

  const createAppointment = async ({
    date,
    patientName,
    email,
    phone,
    comment,
  }: AppointmentForm) => {
    setSubmitting(true);

    const startDate = moment(date);
    const endDate = moment(startDate).add(selectedExamType?.duration, 'm');
    const { docs } = await getDocs<Appointment>(
      query(
        collection(getFirestore(), 'appointments'),
        where(
          'startDate',
          '>=',
          moment(startDate).subtract(90, 'minutes').toDate()
        ),
        where('startDate', '<', endDate.toDate())
      ).withConverter(idConverter)
    );

    try {
      // TODO fix this mess
      if (
        startDate.day() === 0 ||
        startDate.day() === 6 ||
        startDate.hour() < 8 ||
        endDate.hour() > 20 ||
        (endDate.hour() === 20 && endDate.minute() > 0) ||
        docs.some((doc) => {
          const data = doc.data();
          const bookedRange = moment.range(
            data.startDate.toDate(),
            data.endDate.toDate()
          );
          const newRange = moment.range(
            startDate,
            moment(endDate).subtract(1, 'seconds')
          );

          return bookedRange.overlaps(newRange);
        })
      ) {
        message.error('Erre a időpontra nem lehet foglalni');
        setSubmitting(false);

        return;
      }
      const patient = addNewPatient
        ? await createPatient(email, patientName, phone)
        : selectedPatient;

      const newAppointment = removeUndefined({
        patient,
        examType: selectedExamType,
        startDate: Timestamp.fromDate(startDate.toDate()),
        endDate: Timestamp.fromDate(endDate.toDate()),
        timeCreated: serverTimestamp(),
        updated: serverTimestamp(),
        createdBy: authUser?.uid,
        comment,
      });

      appointment
        ? await setDoc(
            doc(collection(getFirestore(), 'appointments'), appointment?.id),
            newAppointment,
            { merge: true }
          )
        : await addDoc(
            collection(getFirestore(), 'appointments'),
            newAppointment
          );

      form.resetFields();

      setSubmitting(false);
      setVisible(false);
    } catch (error) {
      setSubmitting(false);
      console.error(error);
      message.error('Hiba történt');
    }
  };
  const deleteAppointment = async () => {
    if (appointment) {
      await deleteDoc(
        doc(collection(getFirestore(), 'appointments'), appointment.id)
      );

      setVisible(false);
    }
  };

  return (
    <Form
      onFinish={createAppointment}
      form={form}
      layout="vertical"
      initialValues={
        appointment
          ? {
              patientName: getPatientEmailAndName(appointment.patient),
              examType: appointment.examType?.name,
              date: moment(appointment.startDate.toDate()),
              comment: appointment.comment,
            }
          : {
              date:
                initialStartDate ??
                moment().set('h', 0).set('m', 0).set('s', 0),
            }
      }
    >
      <Form.Item
        label="Időpont"
        name={'date'}
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <DatePicker
          showTime
          showSecond={false}
          showNow={false}
          format="YYYY-MM-DD HH:mm"
          hideDisabledOptions={true}
          disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24]}
          disabledDate={
            (current) =>
              current.day() === 0 ||
              current.day() === 6 ||
              (current.isSame(moment(), 'day') && moment().hour() > 18) ||
              moment('2022-03-15').isSame(current, 'day') || // TODO Remove after tomorrow
              current.toDate() < new Date()
            // disable weekends, same day after 18 o'clock and days before
          }
        />
      </Form.Item>
      <Form.Item
        label="Vizsgálat típusa"
        name="examType"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Select
          showSearch
          onSelect={(selected: string) => {
            setSelectedExamType(
              examTypes.find((examType) => examType.name === selected)
            );
          }}
        >
          {examTypes.map(({ name, duration, price }) => (
            <Option
              value={name}
              key={name}
            >{`${name} | ${duration} perc | ${price} Ft`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Új páciens felvétele">
        <Switch
          onChange={(value) => {
            setAddNewPatient(value);
            form.setFieldsValue({ patientName: '' });
          }}
        />
      </Form.Item>
      {addNewPatient ? (
        <>
          <Form.Item
            label="Név"
            name="patientName"
            rules={[{ required: true, message: 'Kötelező mező' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Kötelező mező' },
              { type: 'email', message: 'Helytelen email' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Telefonszám" name="phone">
            <Input />
          </Form.Item>
        </>
      ) : (
        <Form.Item
          label="Páciens"
          name="patientName"
          rules={[
            {
              message: 'Kötelező mező',
              validator: () => {
                return new Promise((resolve, reject) => {
                  if (
                    (!!selectedPatient &&
                      selectedPatient?.id === appointment?.patient.id) ||
                    results.some((result) => result.id === selectedPatient?.id)
                  ) {
                    resolve('');
                  } else {
                    reject('required');
                  }
                });
              },
            },
          ]}
        >
          <AutoComplete
            options={results.map((patient) => ({
              value: getPatientEmailAndName(patient),
            }))}
            onSearch={onSearch}
            onSelect={(selected: string) => {
              setSelectedPatient(
                results.find(
                  (result) => `${result.name}, ${result.email}` === selected
                )
              );
            }}
            placeholder="Kereső"
          />
        </Form.Item>
      )}
      <Form.Item label="Megjegyzés" name="comment">
        <Input />
      </Form.Item>
      <FlexDiv>
        {appointment && (
          <>
            <Popconfirm
              title="Biztos töröljük?"
              okText="Igen"
              cancelText="Nem"
              onConfirm={deleteAppointment}
            >
              <Button type="primary" danger style={{ marginRight: '8px' }}>
                Törlés
              </Button>
            </Popconfirm>
            {
              /* <Button style={{ marginRight: '8px' }}>Törlés</Button> */
              // TODO go to patient button
            }
          </>
        )}

        <Button
          onClick={() => setVisible(false)}
          style={{ marginRight: '8px', marginLeft: 'auto' }}
        >
          Bezár
        </Button>

        <Button type="primary" htmlType="submit" disabled={isSubmitting}>
          {isSubmitting ? <LoadingOutlined /> : 'Mentés'}
        </Button>
      </FlexDiv>
    </Form>
  );
};
