import { FileAddOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  Tooltip,
  Button,
  message,
  Modal,
  Descriptions,
  Select,
  Form,
  Checkbox,
  Space,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { getApp } from 'firebase/app';
import { setDoc, collection, getFirestore, doc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Appointment } from '../models/appointment';

// TODO Active invoice button

interface CreateInvoiceResponse {
  pdf: Buffer;
  fileName: string;
}
const { Option } = Select;

const CreateInvoice = ({ appointment }: { appointment: Appointment }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const createInvoice = async () => {
    setLoading(true);

    try {
      const paymentMethod = form.getFieldValue('paymentMethod');
      const emergency = form.getFieldValue('emergency');
      console.log(emergency);

      const { data } = await httpsCallable<Appointment, CreateInvoiceResponse>(
        getFunctions(getApp(), 'europe-west1'),
        'createInvoice'
      )({
        ...appointment,
        paymentMethod,
        emergency,
      });

      const linkSource = `data:application/pdf;base64,${data.pdf}`;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('target', '_blank');
      downloadLink.href = linkSource;
      downloadLink.download = data.fileName;
      downloadLink.click();

      await setDoc(
        doc(collection(getFirestore(), 'appointments'), appointment.id),
        { invoiceCreated: true, paymentMethod, emergency },
        { merge: true }
      );

      setLoading(false);
      setVisible(false);
    } catch (e) {
      console.error(e);
      message.error('Hiba történt');
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Számlázás">
        <Button
          disabled={appointment.invoiceCreated}
          onClick={() => {
            setVisible(true);
          }}
        >
          <FileAddOutlined />
        </Button>
      </Tooltip>
      <Modal
        title="Számlázás"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Link
            key="cancel"
            to={`/patients/${appointment.patient.id}?tabKey=edit`}
          >
            <Button>Módosítás</Button>
          </Link>,
          <Button key="link">Mégse</Button>,
          <Button key="ok" type="primary" onClick={createInvoice}>
            {loading ? <LoadingOutlined /> : 'Számlázás'}
          </Button>,
        ]}
      >
        <Form
          form={form}
          initialValues={{ paymentMethod: 'Bankkártya', emergency: false }}
        >
          <Space>
            <Form.Item label="Fizetési mód" name="paymentMethod">
              <Select>
                <Option value="Bankkártya">Bankkártya</Option>
                <Option value="Készpénz">Készpénz</Option>
                <Option value="Átutalás">Átutalás</Option>
              </Select>
            </Form.Item>
            <Form.Item name="emergency" valuePropName="checked">
              <Checkbox>Sürgősségi +15000 Ft</Checkbox>
            </Form.Item>
          </Space>
        </Form>
        <Descriptions title="Tételek" layout="vertical" bordered>
          <Descriptions.Item label="Megnevezés">
            Eü szolg díja
          </Descriptions.Item>
          <Descriptions.Item label="Ár">
            {appointment.examType.price} Ft
          </Descriptions.Item>
          <Descriptions.Item label="Megjegyzés">
            {appointment.examType.name}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Descriptions title="Vevő adatok" layout="vertical" bordered>
          <Descriptions.Item label="Név">
            {appointment.patient.name}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {appointment.patient.email}
          </Descriptions.Item>
          <Descriptions.Item label="Irányítószám">
            {appointment.patient.isBillingSame
              ? appointment.patient.zipCode
              : appointment.patient.billingZipCode}
          </Descriptions.Item>
          <Descriptions.Item label="Ország">
            {appointment.patient.isBillingSame
              ? appointment.patient.country
              : appointment.patient.billingCountry}
          </Descriptions.Item>
          <Descriptions.Item label="Város">
            {appointment.patient.isBillingSame
              ? appointment.patient.city
              : appointment.patient.billingCity}
          </Descriptions.Item>
          <Descriptions.Item label="Utca, házszam">
            {appointment.patient.isBillingSame
              ? appointment.patient.street
              : appointment.patient.billingStreet}
          </Descriptions.Item>
          <Descriptions.Item label="Adószám">
            {appointment.patient.taxNumber ?? ''}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default CreateInvoice;
