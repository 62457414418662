import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';
import { useState } from 'react';
import { Appointment } from '../models/appointment';
import { RightAlignedDiv } from '../shared/RightAlignedDiv';

export const DeleteAppointmentModal = ({
  appointment,
}: {
  appointment: Appointment;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  const deleteAppointment = async () => {
    setIsSubmitting(true);

    try {
      await deleteDoc(doc(getFirestore(), 'appointments', appointment.id));
    } catch (error) {
      console.error(error);
      message.error('Hiba a törléskor');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>
        <DeleteOutlined />
      </Button>
      <Modal
        title={`Biztosan töröljük ${appointment.patient.name} időpontját?`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <RightAlignedDiv>
          <Button
            onClick={() => setVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={deleteAppointment}>
            {isSubmitting ? <LoadingOutlined /> : 'Törlés'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};
