import { LoadingOutlined, MailOutlined, MailTwoTone } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';
import { useState } from 'react';
import { Patient, PatientDocument } from '../../models/patient';
import { RightAlignedDiv } from '../../shared/RightAlignedDiv';

export const SendDocumentButton = ({
  patient,
  document,
}: {
  patient: Patient;
  document: PatientDocument;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [visible, setVisible] = useState(false);

  const sendDocumentEmail = async () => {
    setSubmitting(true);

    try {
      await httpsCallable(
        getFunctions(getApp(), 'europe-west1'),
        'sendDocumentEmail'
      )({
        email: patient.email,
        name: patient.name,
        fileName: document.fileName,
        url: document.downloadURL,
      });
    } catch (err) {
      console.error(err);
      message.error('Sikertelen küldés');
    }

    message.success('Sikeres küldés');

    if (!document.alreadySent) {
      await setDoc(
        doc(getFirestore(), `patients/${patient.id}/documents`, document.id),
        { alreadySent: true },
        { merge: true }
      );
    }

    setSubmitting(false);
  };

  return (
    <>
      {document.alreadySent ? (
        <Button onClick={() => setVisible(true)}>
          <MailTwoTone twoToneColor="#2feb3f" />
        </Button>
      ) : (
        <Button onClick={sendDocumentEmail} disabled={submitting}>
          <MailOutlined />
        </Button>
      )}

      <Modal
        title={`Biztosan ki szeretnéd küldeni még egyszer ezt a leletet?`}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <RightAlignedDiv>
          <Button
            onClick={() => setVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Mégse
          </Button>
          <Button type="primary" onClick={() => sendDocumentEmail()}>
            {submitting ? <LoadingOutlined /> : 'Küldés'}
          </Button>
        </RightAlignedDiv>
      </Modal>
    </>
  );
};
