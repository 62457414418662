import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const LoadingSpinner = () => {
  return (
    <SpinnerDiv>
      <LoadingOutlined />
    </SpinnerDiv>
  );
};

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform: scale(5);
`;
