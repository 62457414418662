import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import huLocale from '@fullcalendar/core/locales/hu';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { Appointment } from '../models/appointment';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AddAppointmentForm } from '../appointments/AddAppointmentForm';
import { AddAppointmentModal } from '../appointments/AddAppointmentModal';
import AddPublicAppointmentModal from '../appointments/AddPublicAppointmentModal';
import AddPublicAppointmentForm from '../appointments/AddPublicAppointmentForm';
import moment from 'moment';

const WrapperDiv = styled.div`
  margin: 1rem;
`;

const CenterAlignedDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const headerToolbar = {
  left: 'prev,next',
  center: 'title',
  right: 'timeGridDay,timeGridWeek',
};

export const Calendar = ({ publicMode }: { publicMode?: boolean }) => {
  const [range, setRange] = useState<[Date, Date]>();
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [editingVisible, setEditingVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>();
  const [authUser] = useAuthState(getAuth());
  const [form] = Form.useForm();

  useEffect(() => {
    if (editingVisible) {
      form.resetFields();
    }
  }, [editingVisible, form]);

  useEffect(() => {
    if (range) {
      return onSnapshot(
        query(
          collection(getFirestore(), 'appointments'),
          where('startDate', '>=', range[0]),
          where('startDate', '<', range[1])
        ),
        (snapshot) => {
          setAppointments(
            snapshot.docs.map(
              (doc) => ({ ...doc.data(), id: doc.id } as Appointment)
            )
          );
        }
      );
    }
  }, [range]);

  return (
    <WrapperDiv className="calendar">
      <CenterAlignedDiv>
        {publicMode ? <AddPublicAppointmentModal /> : <AddAppointmentModal />}
      </CenterAlignedDiv>

      {publicMode ? (
        <>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            allDaySlot={false}
            height="auto"
            weekends={false}
            slotMinTime="8:00"
            slotMaxTime="20:00"
            initialView="timeGridWeek"
            headerToolbar={headerToolbar}
            locale={huLocale}
            datesSet={({ start, end }) => {
              if (!range || start !== range[0] || end !== range[1]) {
                setRange([start, end]);
              }
            }}
            events={appointments?.map((appointment) => {
              const userOwned = appointment.createdBy === authUser?.uid;

              return {
                start: appointment.startDate.toDate(),
                end: appointment.endDate.toDate(),
                title: userOwned ? appointment.patient?.name : 'Foglalt',
                id: appointment.id,
                appointment: appointment,
                color: userOwned ? 'default' : 'gray',
              };
            })}
            eventClick={(event) => {
              if (
                event.event.extendedProps?.appointment?.createdBy ===
                authUser?.uid
              ) {
                setSelectedAppointment(event.event.extendedProps.appointment);
                setEditingVisible(true);
              }
            }}
            selectable={true}
            select={(event) => {
              setSelectedStartDate(moment(event.start));
              setSelectedAppointment(undefined);
              setEditingVisible(true);
            }}
          />
          <Modal
            title="Időpont hozzáadasa"
            visible={editingVisible}
            onCancel={() => setEditingVisible(false)}
            footer={null}
          >
            <AddPublicAppointmentForm
              appointment={selectedAppointment}
              setVisible={setEditingVisible}
              initialStartDate={selectedStartDate}
              formInstance={form}
            />
          </Modal>
        </>
      ) : (
        <>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            allDaySlot={false}
            initialView="timeGridWeek"
            headerToolbar={headerToolbar}
            height="auto"
            weekends={false}
            slotMinTime="8:00"
            slotMaxTime="20:00"
            locale={huLocale}
            datesSet={({ start, end }) => {
              if (!range || start !== range[0] || end !== range[1]) {
                setRange([start, end]);
              }
            }}
            events={appointments?.map((appointment) => ({
              start: appointment.startDate.toDate(),
              end: appointment.endDate.toDate(),
              title: appointment.patient?.name,
              id: appointment.id,
              appointment: appointment,
            }))}
            eventClick={(event) => {
              setSelectedAppointment(event.event.extendedProps.appointment);
              setEditingVisible(true);
            }}
            selectable={true}
            select={(event) => {
              setSelectedStartDate(moment(event.start));
              setSelectedAppointment(undefined);
              setEditingVisible(true);
            }}
          />
          <Modal
            title="Időpont módosítása"
            visible={editingVisible}
            onCancel={() => setEditingVisible(false)}
            footer={null}
          >
            <AddAppointmentForm
              setVisible={setEditingVisible}
              appointment={selectedAppointment}
              initialStartDate={selectedStartDate}
              formInstance={form}
            />
          </Modal>
        </>
      )}
    </WrapperDiv>
  );
};
