import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FC, useState } from 'react';
import { getUserRole, User, UserForm } from '../models/user';
import { RightAlignedDiv } from '../shared/RightAlignedDiv';

type Props = { setIsModalVisible: (visibility: boolean) => void; user: User };
const { Option } = Select;

export const EditUserForm: FC<Props> = ({ setIsModalVisible, user }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const editUser = async (userForm: UserForm) => {
    setSubmitting(true);

    try {
      await httpsCallable<any, User>(
        getFunctions(getApp(), 'europe-west1'),
        'editUser'
      )({ ...userForm, uid: user.id });
    } catch (error) {
      console.error(error);
    }

    setSubmitting(false);
    setIsModalVisible(false);
  };

  return (
    <Form
      onFinish={editUser}
      initialValues={{
        email: user.email,
        displayName: user.displayName,
        role: user.role,
      }}
    >
      <Form.Item
        label="Név"
        name="displayName"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Kötelező mező' },
          { type: 'email', message: 'helytelen email' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Jogosultság" name="role">
        <Select>
          <Option value={0}>{getUserRole(0)}</Option>
          <Option value={1}>{getUserRole(1)}</Option>
          <Option value={2}>{getUserRole(2)}</Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <RightAlignedDiv>
          <Button
            onClick={() => setIsModalVisible(false)}
            style={{ marginRight: '8px' }}
          >
            Bezár
          </Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting ? <LoadingOutlined /> : 'Mentes'}
          </Button>
        </RightAlignedDiv>
      </Form.Item>
    </Form>
  );
};
