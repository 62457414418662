import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import { getAuth } from 'firebase/auth';
import {
  serverTimestamp,
  query,
  collection,
  getFirestore,
  where,
  addDoc,
  getDocs,
  Timestamp,
} from 'firebase/firestore';
import moment from 'moment';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Patient, PatientForm } from '../models/patient';
import { removeUndefined } from '../shared/converters';
import './Patients.scss';

const { Option } = Select;

export const AddPatientForm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isBillingSame, setIsBillingSame] = useState(true);
  const [form] = Form.useForm();
  const [authUser] = useAuthState(getAuth());

  const createPatient = async (patientForm: PatientForm) => {
    setSubmitting(true);

    const newPatient: Partial<Patient> = removeUndefined({
      ...patientForm,
      birthDate:
        patientForm.birthDate &&
        Timestamp.fromDate(patientForm.birthDate.toDate()),
      timeCreated: serverTimestamp() as Timestamp,
      updated: serverTimestamp() as Timestamp,
      createdBy: authUser?.uid,
    });

    try {
      const existingPatient = await getDocs(
        query(
          collection(getFirestore(), 'patients'),
          where('email', '==', patientForm.email)
        )
      );

      if (!existingPatient.empty) {
        message.error('Email cím már használatban');
      } else {
        await addDoc(collection(getFirestore(), 'patients'), newPatient);
        message.success('Sikeres mentés');
        navigate('/patients');
      }
    } catch (error) {
      console.error(error);
      message.error('Hiba történt');
      setSubmitting(false);
    }
  };

  return (
    <Form
      onFinish={createPatient}
      form={form}
      layout="vertical"
      initialValues={{
        documentType: 'taj',
        birthDate: moment('1970'),
        isBillingSame,
      }}
    >
      <Row>
        <Col span={12}>
          <Card title="Adatok">
            <Form.Item
              label="Név"
              name="name"
              rules={[{ required: true, message: 'Kötelező mező' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Kötelező mező' },
                { type: 'email', message: 'Helytelen email' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Telefonszám" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Anyja neve" name="mothersName">
              <Input />
            </Form.Item>
            <Form.Item label="Születési hely" name="birthPlace">
              <Input />
            </Form.Item>
            <Form.Item label="Születési dátum (éééé-hh-nn)" name="birthDate">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Azonosító okmány típusa" name="documentType">
              <Select>
                <Option value="taj">TAJ</Option>
                <Option value="szig">Személyi igazolvány</Option>
                <Option value="passport">Útlevél</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Azonosító okmány száma" name="documentNumber">
              <Input />
            </Form.Item>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Lakcím">
            <Form.Item label="Irányítószám" name="zipCode">
              <Input />
            </Form.Item>
            <Form.Item label="Ország" name="country">
              <Input placeholder="Magyarország" />
            </Form.Item>
            <Form.Item label="Város" name="city">
              <Input />
            </Form.Item>
            <Form.Item label="Utca, házszam" name="street">
              <Input />
            </Form.Item>
          </Card>

          <Card title="Számlázás">
            <Form.Item label="Adószám" name="taxNumber">
              <Input />
            </Form.Item>
            <Form.Item
              label="Lakcím és számlázási cím azonos"
              name="isBillingSame"
              valuePropName="checked"
            >
              <Checkbox
                onChange={(event) => setIsBillingSame(event.target.checked)}
              />
            </Form.Item>

            {!isBillingSame && (
              <>
                <Form.Item label="Irányítószám" name="billingZipCode">
                  <Input />
                </Form.Item>
                <Form.Item label="Ország" name="billingCountry">
                  <Input />
                </Form.Item>
                <Form.Item label="Város" name="billingCity">
                  <Input />
                </Form.Item>
                <Form.Item label="Utca, házszam" name="billingStreet">
                  <Input />
                </Form.Item>
              </>
            )}
          </Card>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isSubmitting}
          style={{ margin: '2rem 0' }}
          block
        >
          {isSubmitting ? <LoadingOutlined /> : 'Mentés'}
        </Button>
      </Form.Item>
    </Form>
  );
};
