import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Popconfirm,
  Select,
} from 'antd';
import { getAuth } from 'firebase/auth';
import {
  Timestamp,
  serverTimestamp,
  addDoc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  setDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Appointment, AppointmentForm } from '../models/appointment';
import { Patient } from '../models/patient';
import { idConverter, removeUndefined } from '../shared/converters';
import { FlexDiv } from '../shared/RightAlignedDiv';
import { examTypes } from './exam-types';

const { Option } = Select;

const moment = extendMoment(Moment);

const AddPublicAppointmentForm = ({
  appointment,
  initialStartDate,
  formInstance,
  setVisible,
}: {
  appointment?: Appointment;
  initialStartDate?: moment.Moment;
  formInstance?: FormInstance;
  setVisible: (visibility: boolean) => void;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedExamType, setSelectedExamType] = useState(
    appointment?.examType
  );
  const [authUser] = useAuthState(getAuth());
  const [form] = Form.useForm(formInstance);

  const createPatient = async (
    email = '',
    patientName = '',
    phone = ''
  ): Promise<Patient> => {
    const existingPatients = await getDocs<Patient>(
      query(
        collection(getFirestore(), 'patients'),
        where('email', '==', email),
        where('name', '==', patientName)
      ).withConverter(idConverter)
    );

    if (existingPatients.empty) {
      const newPatient = removeUndefined({
        name: patientName,
        email,
        phone,
        timeCreated: serverTimestamp() as Timestamp,
        updated: serverTimestamp() as Timestamp,
        createdBy: authUser?.uid,
      });

      const { id } = await addDoc(
        collection(getFirestore(), 'patients'),
        newPatient
      );

      return { ...newPatient, id };
    } else {
      return existingPatients.docs[0].data();
    }
  };

  const createAppointment = async ({
    date,
    patientName,
    email,
    phone,
    comment,
  }: AppointmentForm) => {
    setSubmitting(true);

    const startDate = moment(date);
    const endDate = moment(startDate).add(selectedExamType?.duration, 'm');
    const { docs } = await getDocs<Appointment>(
      query(
        collection(getFirestore(), 'appointments'),
        where(
          'startDate',
          '>=',
          moment(startDate).subtract(90, 'minutes').toDate()
        ),
        where('startDate', '<', endDate.toDate())
      ).withConverter(idConverter)
    );

    try {
      if (
        startDate.day() === 0 ||
        startDate.day() === 6 ||
        startDate.hour() < 8 ||
        endDate.hour() > 20 ||
        (endDate.hour() === 20 && endDate.minute() > 0) ||
        docs.some((doc) => {
          const data = doc.data();
          const bookedRange = moment.range(
            data.startDate.toDate(),
            data.endDate.toDate()
          );
          const newRange = moment.range(
            startDate,
            moment(endDate).subtract(1, 'seconds')
          );

          return bookedRange.overlaps(newRange);
        })
      ) {
        message.error('Erre a időpontra nem lehet foglalni');
        setSubmitting(false);

        return;
      }
      const patient = await createPatient(email, patientName, phone);

      const newAppointment = removeUndefined({
        patient,
        examType: selectedExamType,
        startDate: Timestamp.fromDate(startDate.toDate()),
        endDate: Timestamp.fromDate(endDate.toDate()),
        timeCreated: serverTimestamp(),
        updated: serverTimestamp(),
        createdBy: authUser?.uid,
        comment,
      });

      appointment
        ? await setDoc(
            doc(collection(getFirestore(), 'appointments'), appointment?.id),
            newAppointment,
            { merge: true }
          )
        : await addDoc(
            collection(getFirestore(), 'appointments'),
            newAppointment
          );

      form.resetFields();

      setSubmitting(false);
      setVisible(false);
    } catch (error) {
      setSubmitting(false);
      console.error(error);
      message.error('Hiba történt');
    }
  };
  const deleteAppointment = async () => {
    if (appointment) {
      await deleteDoc(
        doc(collection(getFirestore(), 'appointments'), appointment.id)
      );
    }
  };

  return (
    <Form
      onFinish={createAppointment}
      form={form}
      layout="vertical"
      initialValues={{
        date: appointment
          ? moment(appointment.startDate.toDate())
          : initialStartDate ?? moment().set('h', 0).set('m', 0).set('s', 0),
        patientName: appointment?.patient.name,
        email: appointment?.patient.email,
        phone: appointment?.patient.phone,
        examType: appointment?.examType.name,
      }}
    >
      <Form.Item
        label="Időpont"
        name="date"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <DatePicker
          showTime
          showSecond={false}
          showNow={false}
          format="YYYY-MM-DD HH:mm"
          minuteStep={5}
          hideDisabledOptions={true}
          disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24]}
          disabledDate={
            (current) =>
              current.day() === 0 ||
              current.day() === 6 ||
              (current.isSame(moment(), 'day') && moment().hour() > 18) ||
              moment('2022-03-15').isSame(current, 'day') || // TODO Remove after tomorrow
              current.toDate() < new Date()
            // disable weekends, same day after 18 o'clock and days before
          }
        />
      </Form.Item>
      <Form.Item
        label="Vizsgálat típusa"
        name="examType"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Select
          showSearch
          onSelect={(selected: string) => {
            setSelectedExamType(
              examTypes.find((examType) => examType.name === selected)
            );
          }}
        >
          {examTypes.map(({ name, duration, price }) => (
            <Option
              value={name}
              key={name}
            >{`${name} | ${duration} perc | ${price} Ft`}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Név"
        name="patientName"
        rules={[{ required: true, message: 'Kötelező mező' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: 'Kötelező mező' },
          { type: 'email', message: 'Helytelen email' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Telefonszám" name="phone">
        <Input />
      </Form.Item>
      <Form.Item label="Megjegyzés" name="comment">
        <Input />
      </Form.Item>
      <FlexDiv>
        {appointment && (
          <Popconfirm
            title="Biztos töröljük?"
            okText="Igen"
            cancelText="Nem"
            onConfirm={deleteAppointment}
          >
            <Button type="primary" danger style={{ marginRight: '8px' }}>
              Törlés
            </Button>
          </Popconfirm>
        )}
        <Button
          onClick={() => {
            setVisible(false);
          }}
          style={{ marginRight: '8px', marginLeft: 'auto' }}
        >
          Bezár
        </Button>
        <Button type="primary" htmlType="submit" disabled={submitting}>
          {submitting ? <LoadingOutlined /> : 'Mentés'}
        </Button>
      </FlexDiv>
    </Form>
  );
};

export default AddPublicAppointmentForm;
