import algoliasearch from 'algoliasearch/lite';
import { Input, Space, Table } from 'antd';
import {
  collection,
  documentId,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import { Appointment, ExamType } from '../models/appointment';
import { Patient } from '../models/patient';
import { idConverter } from '../shared/converters';
import { AddAppointmentModal } from './AddAppointmentModal';
import CreateInvoice from './CreateInvoice';
import { DeleteAppointmentModal } from './DeleteAppointmentModal';

const searchClient = algoliasearch(
  'LSUM0IUQ72',
  '69f9c17a6d5587acb64826ea2eb8b31c'
);
const index = searchClient.initIndex('appointments');

export const Appointments = () => {
  const [appointments, loading, error] = useCollectionData<Appointment>(
    query(
      collection(getFirestore(), 'appointments'),
      orderBy('startDate', 'desc'),
      limit(10)
    ).withConverter(idConverter)
  );
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<Appointment[]>([]);
  const [searchData, setSearchData] = useState<Appointment[]>();
  useEffect(() => {
    if (searchResults.length > 0) {
      return onSnapshot(
        query(
          collection(getFirestore(), 'appointments'),
          where(
            documentId(),
            'in',
            searchResults.map((hit) => hit.id)
          )
        ),
        (snapshot) => {
          setSearchData(
            searchResults.reduce((acc, hit) => {
              const document = snapshot.docs.find((doc) => doc.id === hit.id);

              if (document) {
                acc.push({
                  ...document.data(),
                  id: document.id,
                } as Appointment);
              }

              return acc;
            }, [] as Appointment[])
          );
        }
      );
    }
  }, [searchResults]);
  const getSearchResults = async (searchInput: string) => {
    if (searchInput) {
      const { hits } = await index.search<Appointment>(searchInput, {
        hitsPerPage: 10,
        attributesToHighlight: [],
        restrictSearchableAttributes: ['patient.name'],
      });

      if (hits.length === 0) {
        setShowSearchResults(true);
        setSearchResults([]);

        return;
      }

      setShowSearchResults(true);
      setSearchResults(hits.map((hit) => ({ ...hit, id: hit.objectID })));
    } else {
      setShowSearchResults(false);
      setSearchResults([]);
    }
  };
  const debounceFn = useMemo(() => debounce(getSearchResults, 500), []);

  const columns = [
    {
      title: (
        <Space>
          Név
          <Input
            placeholder="Kereső"
            onChange={(event) => debounceFn(event.target.value)}
            allowClear={true}
          />
        </Space>
      ),
      dataIndex: 'patient',
      render: (patient: Patient) => (
        <Link to={`/patients/${patient.id}?tabKey=edit`}>{patient.name}</Link>
      ),
    },
    {
      title: 'Kezdés',
      dataIndex: 'startDate',
      render: (startDate: Timestamp) =>
        moment(startDate.toDate()).format('YYYY. MM. DD. HH:mm').toString(),
    },
    {
      title: 'Vizsgálat',
      dataIndex: 'examType',
      render: ({ name, duration }: ExamType) => `${name} | ${duration} perc`,
    },
    {
      title: <AddAppointmentModal />,
      dataIndex: ['operation'],
      render: (_: undefined, appointment: Appointment) => (
        <>
          <AddAppointmentModal appointment={appointment} />
          <CreateInvoice appointment={appointment} />
          <DeleteAppointmentModal appointment={appointment} />
        </>
      ),
    },
  ];

  if (error) {
    console.error(error);
  }

  return (
    <Table
      dataSource={showSearchResults ? searchData : appointments}
      pagination={false}
      columns={columns}
      rowKey="id"
      loading={loading}
    />
  );
};
